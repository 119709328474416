const dashboard = 'dashboard';
const upload = 'upload';
const userDetails = 'user_details';
const organization = 'organization';
const incentiveProgram = 'incentive_program';
const pincode = 'pin_codes';
const roles = 'roles';
const modulePermissions = 'module_master_details';
const rolePermissions = 'role_permission_details';
const userEvalPermissionDetails = `${process.env.REACT_APP_API_URL}/user_eval_permission_details/`;
const downloadTemplate = `${process.env.REACT_APP_API_URL}/download_augment_user_excel/`;
const updateUserLanguage = 'update_user_language';
const getTimezoneList = 'get_timezone_list';
const userRoles = 'get_user_roles_program_details';
const updateUserRoleProgramDetails = 'update_user_role_program_details';
const inputSourceFileUpload = 'input_data_file_upload';
const downloadUserTemplateBaseUrl = `${process.env.REACT_APP_API_URL}/download_augment_user_excel`;
const downloadUserTemplate = 'download_augment_user_excell';
const bulkUserBaseUrl = `${process.env.REACT_APP_API_URL}/get_user_bulk_upload_data`;
const createSuperUser = 'create_super_user';

const apiEndpoint = {
  dashboard,
  upload,
  organization,
  incentiveProgram,
  pincode,
  roles,
  modulePermissions,
  rolePermissions,
  userEvalPermissionDetails,
  downloadTemplate,
  updateUserLanguage,
  getTimezoneList,
  userRoles,
  updateUserRoleProgramDetails,
  inputSourceFileUpload,
  downloadUserTemplate,
  downloadUserTemplateBaseUrl,
  bulkUserBaseUrl,
  createSuperUser,
  userDetails,
};

export default apiEndpoint;

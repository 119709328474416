// Package Imports
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Authenticated } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { ThemedLayoutV2 } from '@refinedev/antd';

// Project Imports
import DashboardRoute from 'core/routes/DashboardRoute';
import ErrorRoute from 'core/routes/ErrorRoute';
import LoginRoute from 'core/routes/LoginRoute';
import Header from 'components/header';
import RegisterRoute from 'core/routes/Register';
import routeName from 'core/common/routeName';
import resourceName from 'core/common/resourceName';
import OrganizationRoute from 'core/routes/OrganizationRoute';
import UsersRoute from 'core/routes/UsersRoute';
import RolesRoute from 'core/routes/RolesRoute';
import IncentiveProgramRoute from 'core/routes/IncentiveProgramRoute';
import CustomSider from 'components/sider/sider';
import AttributeRoute from 'core/routes/AttributeRoute';
import PermissionRoute from 'core/routes/PermissionsRoute';
import ProfileRoute from 'core/routes/ProfileRoute';
import BulkUserRoute from 'core/routes/BulkUserRoute';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to={routeName.login} />}>
          <ThemedLayoutV2
            Header={() => <Header />}
            Sider={() => <CustomSider />}
          >
            <Outlet />
          </ThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route
        index
        element={<NavigateToResource resource={resourceName.dashboard} />}
      />
      {DashboardRoute}
      {OrganizationRoute}
      {RolesRoute}
      {IncentiveProgramRoute}
      {UsersRoute}
      {ErrorRoute}
      {RegisterRoute}
      {AttributeRoute}
      {PermissionRoute}
      {ProfileRoute}
      {BulkUserRoute}
    </Route>
    {ErrorRoute}
    {RegisterRoute}
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource />
        </Authenticated>
      }
    >
      {LoginRoute}
    </Route>
  </Routes>
);

export default AppRoutes;

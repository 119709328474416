// Package Imports
import React, { useEffect } from 'react';
import {
  useThemedLayoutContext,
  type RefineThemedLayoutV2SiderProps,
} from '@refinedev/antd';
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated,
  useActiveAuthProvider,
  useIsExistAuthentication,
  useLink,
  useLogout,
  useMenu,
  useRefineContext,
  useRouterContext,
  useRouterType,
  useTranslate,
} from '@refinedev/core';

// Project Imports;
import { Button, Drawer, Grid, Layout, Menu, theme } from 'antd';
import {
  BarsOutlined,
  DashboardOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Images } from 'core/common/imagePath';
import './index.css';

const { SubMenu } = Menu;
const { useToken } = theme;

const CustomSider: React.FC<RefineThemedLayoutV2SiderProps> = () => {
  const { token } = useToken();
  const t = useTranslate();
  const {
    siderCollapsed,
    setSiderCollapsed,
    mobileSiderOpen,
    setMobileSiderOpen,
  } = useThemedLayoutContext();

  const isExistAuthentication = useIsExistAuthentication();
  const routerType = useRouterType();
  const NewLink = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const Link = routerType === 'legacy' ? LegacyLink : NewLink;
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();
  const authProvider = useActiveAuthProvider();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  useEffect(() => {
    setSiderCollapsed(true);
  }, []);

  const isMobile =
    typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKeyy?: string) =>
    tree.map((item: ITreeMenu) => {
      const { icon, route, key, name, children, parentName, meta, options } =
        item;
      if (children.length > 0) {
        return (
          <SubMenu
            key={item.key}
            icon={icon ?? <UnorderedListOutlined />}
            title={t(`${item.name}.${item.name}`)}
          >
            {renderTreeView(children, selectedKeyy)}
          </SubMenu>
        );
      }
      const isSelected = key === selectedKeyy;
      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
          undefined && children.length === 0
      );

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={item.key}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route ?? ''}>{t(`${item.name}.${item.name}`)}</Link>
            {!siderCollapsed && isSelected ? (
              <div className="ant-menu-tree-arrow" />
            ) : null}
          </Menu.Item>
        </CanAccess>
      );
    });

  const handleLogout = () => {
    mutateLogout();
  };

  const logout = isExistAuthentication && (
    <Menu.Item
      key="logout"
      onClick={() => handleLogout()}
      icon={<LogoutOutlined />}
    >
      {translate('buttons.logout', 'Logout')}
    </Menu.Item>
  );

  const dashboard = hasDashboard ? (
    <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
      <Link to="/">{translate('dashboard.title', 'Dashboard')}</Link>
      {!siderCollapsed && selectedKey === '/' ? (
        <div className="ant-menu-tree-arrow" />
      ) : null}
    </Menu.Item>
  ) : null;

  const renderSider = () => (
    <>
      {dashboard}
      {renderTreeView(menuItems, selectedKey)}
      {logout}
    </>
  );

  const renderMenu = () => (
    <Menu
      selectedKeys={selectedKey ? [selectedKey] : []}
      defaultOpenKeys={defaultOpenKeys}
      mode="inline"
      className="custom-menu"
      onClick={() => {
        setMobileSiderOpen(false);
      }}
    >
      {renderSider()}
    </Menu>
  );
  const renderDrawerSider = () => (
    <>
      <Drawer
        open={mobileSiderOpen}
        onClose={() => setMobileSiderOpen(false)}
        placement="left"
        closable={false}
        width={200}
        bodyStyle={{
          padding: 0,
        }}
        maskClosable={true}
      >
        <Layout>
          <Layout.Sider className="layout-slider">
            <div className="layout-slider-wrap">
              {siderCollapsed ? (
                <img width="40px" src={Images.AugmentLogoSmall} />
              ) : (
                <img height="64px" src={Images.AugmentLogo} />
              )}
            </div>
            {renderMenu()}
          </Layout.Sider>
        </Layout>
      </Drawer>
      <Button
        className="layout-slider-button"
        size="large"
        onClick={() => setMobileSiderOpen(true)}
        icon={<BarsOutlined />}
      />
    </>
  );

  if (isMobile) {
    return renderDrawerSider();
  }

  const siderStyles: React.CSSProperties = {
    backgroundColor: token.colorBgContainer,
    borderRight: `1px solid ${token.colorBgElevated}`,
  };

  return (
    <Layout.Sider
      style={siderStyles}
      collapsible
      collapsed={!siderCollapsed}
      onCollapse={(collapsed, type) => {
        if (type === 'clickTrigger') {
          setSiderCollapsed(!collapsed);
        }
      }}
      collapsedWidth={80}
      breakpoint="lg"
      trigger={
        <Button className="sider-arrow">
          {!siderCollapsed ? (
            <RightOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          ) : (
            <LeftOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          )}
        </Button>
      }
    >
      <div className={`sider-logo ${!siderCollapsed ? 'collapsed' : ''}`}>
        {!siderCollapsed ? (
          <img width="40px" src={Images.AugmentLogoSmall} />
        ) : (
          <img height="64px" src={Images.AugmentLogo} />
        )}
      </div>
      {renderMenu()}
    </Layout.Sider>
  );
};

export default CustomSider;
